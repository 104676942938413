import PropTypes from 'prop-types'
// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { TextField } from '@mui/material'
import { MaskField } from 'react-mask-field'

// ----------------------------------------------------------------------

RHFTextFieldPhone.propTypes = {
  name: PropTypes.string,
  mask: PropTypes.string,
  inputRef: PropTypes.string,
}

function CustomMaskField({ inputRef, ...otherProps }) {
  return <MaskField ref={inputRef} mask="(__)_____-____" replacement="_" {...otherProps} />
}

CustomMaskField.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
}

export default function RHFTextFieldPhone({ name, ...other }) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          InputProps={{ inputComponent: CustomMaskField }}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  )
}
