import { useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import { alpha } from '@mui/material/styles'
import { Box, Divider, Typography, Stack, MenuItem, IconButton } from '@mui/material'
// _store
import { withStore } from '../../_store/StoreContext'
// components
import MenuPopover from '../../components/MenuPopover'
import Iconify from '../../components/Iconify'

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Estações',
    icon: 'eva:home-fill',
    linkTo: '/app/stations',
  },
  {
    label: 'Carregar',
    path: 'eva:charging-fill',
    linkTo: '/app/charge',
  },
  {
    label: 'Histórico',
    icon: 'eva:person-fill',
    linkTo: '/app/history',
  },
  {
    label: 'Suporte',
    icon: 'eva:settings-2-fill',
    linkTo: '/app/support',
  },
]

// ----------------------------------------------------------------------

function AccountPopover(store) {
  const st = store.store
  const { user } = st

  const [open, setOpen] = useState(null)

  const anchorRef = useRef(null)

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const logout = () => st.logout()

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          display: { lg: 'none' },
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Iconify color="#36209E" icon="eva:menu-2-fill" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.firstName} {user.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem to={'/'} component={RouterLink} onClick={logout} sx={{ m: 1 }}>
          Sair
        </MenuItem>
      </MenuPopover>
    </>
  )
}

export default withStore(AccountPopover)
