// component
import Iconify from '../../components/Iconify'

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />

const navConfig = [
  {
    title: 'Estações',
    path: '/app/stations',
    icon: getIcon('eva:home-fill'),
  },
  {
    title: 'Carregar',
    path: '/app/charge',
    icon: getIcon('eva:charging-fill'),
  },
  {
    title: 'Histórico',
    icon: getIcon('eva:person-fill'),
    path: '/app/history',
  },
  {
    title: 'Suporte',
    path: '/app/support',
    icon: getIcon('eva:settings-2-fill'),
  }
]

export default navConfig
