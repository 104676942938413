import { useState, useEffect } from 'react'
import { Grid, Container, Typography, Button} from '@mui/material'
import Page from '../../components/Page'
import { withStore } from '../../_store/StoreContext'
import {
  ChargesHistory,
  AppWebsiteVisits,
  AppWidgetSummary,
  
} from '../../sections/app/dashBoard'


// ----------------------------------------------------------------------

function DashboardApp(store) {
  const st = store.store
  const { charges } = st

  const[totalCharges, setTotalCharges]= useState("0")
  const[averageTime, setaverageTime]= useState("0")
  const[totalKw, setTotalKw]= useState("0")
  const[opt, setOpt] = useState("general")
  const[chartLabels, setChartLabels] = useState([])
  const[chartData, setChartData] = useState([])

  useEffect(() => {
    _initializer()
      .catch(console.error)
  }, []);

  const _initializer = async () => {
    const ch = await st.chargesHistory()
    console.log(ch)
    setPartials(ch)
    setChartValues(ch.charges)
  }

  const handleSelect = (opt) => {
    setOpt(opt)
  }

  const setPartials = (payload) => {
    setTotalCharges(payload.total.toString() )
    setTotalKw(payload.totalKw)
    setaverageTime(payload.averageTime)
  }

  const setChartValues = (ch) => {
    const data = ch.reduce((acc, charge) => {
      const date = charge.createdAt.split('T')[0]
      if(!acc[date]) {
        acc[date] = 0
      }
      acc[date] += charge.stopValue
      return acc
    }, {})

    setChartLabels(Object.keys(data))
    setChartData([{
      name: "stations",
      type: 'column',
      fill: 'solid',
      data: Object.values(data)
    }])
  }

  const general = () => (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary key="total_recargas" title="Total de Recargas" total={totalCharges} color="success" icon={'eva:charging-fill'} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Tempo total de recarga" total={averageTime} color="info" icon={'ic:round-access-time-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Total de Kw/h" total={totalKw} color="info" icon={'mdi:electricity-circle'} />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            <ChargesHistory
              title="Ultimas Recargas"
              list={charges.map((charge) => ({
                id: charge.uuid,
                title: charge.stationName,
                description: charge.stopValue,
                image: `/static/avatars/avatar_default.jpg`,
                postedAt: charge.startDate || charge.createdAt,
              }))}
            />
          </Grid>
        </Grid>
      </>
    )

  const history = () => (
      <>
        <Grid item>
          <AppWebsiteVisits
            title="Histórico"
            chartLabels={chartLabels}
              chartData={chartData}
          />
          <Grid item xs={12} mt={6} md={6} lg={12}>
            <ChargesHistory
              title="Recargas"
              list={charges.map((charge) => ({
                id: charge.uuid,
                title: charge.stationName,
                description: charge.stopValue,
                image: `/static/avatars/avatar_default.jpg`,
                postedAt: charge.startDate || charge.createdAt,
              }))}
            />
          </Grid>
        </Grid>
      </>
    )

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Histórico de Recargas
        </Typography>
        
        <Grid sx={{ mb: 2 }}>
          <Button 
            size="large"
            variant='contained' 
            color="secondary" 
            sx={{mr: 2, mb:'8px', maxWidth: '403px', width: '100%'}}
            onClick={() => handleSelect("general")}
          >
            Geral
          </Button>
          <Button 
            size="large"
            variant='contained' 
            color="secondary" 
            sx={{mr: 2, mb:'8px', maxWidth: '403px', width: '100%'}}
            onClick={() => handleSelect("historic")}
            >
            Histórico
          </Button>
        </Grid>

        {opt === "general"? general() : history()}
        
      </Container>
    </Page>
  )
}



export default withStore(DashboardApp)
